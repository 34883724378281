<template>
  <div class="recipe-section">
    <div class="section-header">
      <div class="recipe-section-title" v-if="!skeletonMode">{{ section.title }}</div>
      <ion-skeleton-text class="recipe-section-title skeleton-recipe-section-title" animated v-if="skeletonMode" />
      <div class="section-more" v-if="!skeletonMode" @click="showAll()">Alle anzeigen</div>
    </div>
    <div class="section-recipes-outside">
      <div class="section-recipes" v-if="!skeletonMode">
        <RecipeCard
          class="section-recipe-card"
          v-for="r in section.recipes"
          :recipe="r"
          :key="r.id"
          :showAvailability="true"
          />
        <!-- TODO: showAvailability exclude favorited section tab -->
        <div class="space-after-cards"></div>
      </div>
      <div class="section-recipes" v-if="skeletonMode">
        <ion-skeleton-text animated v-for="i in 2" :key="i" class="skeleton-section-recipe-card" />
        <div class="space-after-cards"></div>
      </div>
    </div>
    <!-- <div class="prevent-scrolling-here"></div> -->
  </div>
</template>

<script>
import RecipeCard from '@/components/cards/RecipeCard.vue'
import { IonSkeletonText } from '@ionic/vue'

export default {
  props: {
    'section': Object,
    'skeletonMode': Boolean
  },
  components: {
    RecipeCard,
    IonSkeletonText
  },
  methods: {
    showAll () {
      this.$router.push({ 
        name: this.section.endpoint, 
        params: { 
          title: this.section.title, 
          endpoint: this.section.endpoint, 
          page: JSON.stringify(this.section.page),
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.recipe-section {
  position: relative;
  margin-bottom: 20px;
}

.recipe-section-title {
  @extend .section-title;
  margin: 0 0 3px 30px;
}

.skeleton-recipe-section-title {
  max-width: 50%;
  height: 20px;
}

.skeleton-section-recipe-card {
  height: 331px;
  max-width: 300px;
  margin: 10px;
  min-width: 80vw;
}

.section-recipes-outside {
  z-index: 1;
  overflow-x: scroll;
  padding: 20px 0;
  margin: -20px 0;
  scroll-snap-type: x proximity;
  overscroll-behavior-x: contain;
}
.section-recipes-outside::-webkit-scrollbar {
  display: none;
}

.section-recipes {
  display: flex;
  transform-origin: top left;
  margin-left: 10px;
}

.section-recipe-card {
  margin: 10px !important;
  scroll-snap-align: center;
}

.space-after-cards {
  height: auto;
  min-width: 20px;
}

// .prevent-scrolling-here {
//   position: absolute;
//   height: 100%;
//   width: 55px;
//   top: 0;
//   left: 0;
//   z-index: 1;
// }
</style>
