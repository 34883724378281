<!--

      This view displays the list of recent orders. It is reachable from within
      the profile tab.

 -->

<template>
  <div @click="$store.dispatch('goTo', 'search')">
    <div class="goto-search">
      <Icon name="searchOutline" class="icon-goto-search" />
      <span class="secondary-text-gray">Zutat, Rezept</span>
    </div>
  </div>
  <RecipeSection 
    v-for="section in sections" 
    :section="section" 
    :key="section.id"
    :skeletonMode="!section.loaded"
  />
  <RecipeCategories @edited="downloadRecipeData()"/>
  <InfoBox icon="foodableHeart">
    Markiere Rezepte, die dir gefallen als Favorit, indem du auf das Herz klickst. So lernen wir, was dir gefällt und unsere Empfehlungen für dich werden immer besser!
  </InfoBox>
</template>

<script>
import Page from '@/components/Page.vue'
import RecipeSection from '@/components/RecipeSection'
import InfoBox from '@/components/info/InfoBox'
import RecipeCategories from '@/components/settings/RecipeCategories'

export default {
  name: 'recipes',
  components: {
    Page,
    RecipeSection,
    InfoBox,
    RecipeCategories
  },
  data () {
    return {
      showSearch: null,
      data: null,
      sections: [],
      loading: null,
      loadingMore: null,
      ABOVE_THE_FOLD: 3, // Load and show this number of recipes first. 3 is best. If one fails, there will at least be two.
    }
  },
  computed: {
    orderChanged () {
      if (this.$store.state.neworder) {
        return this.$store.state.neworder.price
      }
    },
    profileExclusionsChanged () {
      return this.$store.getters.profileExclusionsCounter
    }
  },
  watch: {
    orderChanged () {
      this.refreshSections()
      this.updatePriceDeltasOfAllRecipes()
    },
    profileExclusionsChanged () {
      this.downloadRecipeData()
    }
  },
  methods: {
    async downloadRecipeData () {
      this.loading = true
      this.loadingMore = true
      await this.$store.dispatch('getSelectedRecipeCategories')
      const selectedRecipeCategories = JSON.parse(JSON.stringify(this.$store.state.selectedRecipeCategories))
      if (selectedRecipeCategories && selectedRecipeCategories.length > 0) {
        let categoriesToShow = selectedRecipeCategories.sort((a,b) => a.position - b.position)
        // If the favorites are empty, remove the category.
        if (this.$store.state.recipes && this.$store.state.recipes.filter(r => r.is_liked).length === 0) {
          categoriesToShow = categoriesToShow.filter(r => r.endpoint !== 'favorites')
        }
        // We aim to first create areas for each section that a user has selected
        // and them remove them if no recipe is found for the section.
        // Thus, we need to first set this.sections to all categories a user has selected.
        this.sections = categoriesToShow 

        // Recipe data will only be sent if the user has a store branch.
        await this.$store.dispatch('ensureStorebranchIsSet')

        // Download the data needed to present the categories and their recipes.
        const aboveTheFoldSections = this.buildSections(categoriesToShow.slice(0,this.ABOVE_THE_FOLD))
        this.addSectionsToView(await Promise.all(aboveTheFoldSections))
        this.loading = false

        const otherSections = this.buildSections(categoriesToShow.slice(this.ABOVE_THE_FOLD))
        this.addSectionsToView(await Promise.all(otherSections))
        this.loadingMore = false

        this.getRecipesAvailability()
        this.recordRecipeImpressions()
      }
    },
    buildSections(arrayOfCategories) {
      return arrayOfCategories.map(async source => {
        return this.getRecipes(source.endpoint).then(data => {
          if (data && data.results && data.results.length) {
            this.$router.addRoute({
              path: '/tabs/cooking/discover/' + source.endpoint,
              name: source.endpoint,
              component: () => import('@/views/shopping/RecipeList'),
              props: true
            })
            return { title: source.title, recipes: data.results, endpoint: source.endpoint, page: data }
          } else {
            return { title: undefined, recipes: undefined, endpoint: source.endpoint, page: undefined }
          }
        })
      })
    },
    async getRecipes (endpoint) {
      if (this.$store.state.newplan) {
        await this.$store.dispatch('ensureStorebranchIsSet')
        let url
        if (this.$store.state.replaceme) {
          url = `${this.$store.state.api.plan.weekly}${this.$store.state.newplan.id}/replace/${this.$store.state.replaceme.id}/${endpoint}/`
        } else {
          url = `${this.$store.state.api.plan.weekly}${this.$store.state.newplan.id}/add/${endpoint}/`
        }
        return this.$store.dispatch('apiSend', { url: url }).then(results => {
          if (results && results.hasOwnProperty('results')) {
            return results
          }
        })
      } else if (this.$store.getters.oldCustomerJourney) {
        this.$store.dispatch('showNotification', { message: 'Bitte erstelle eine neue Kochbox oder öffne eine alte. Dann können wir dir hier Rezepte anzeigen und ihre Preise in Bezug auf das, was du schon im Warenkorb hast.', type: 'Warning' })
      }
    },
    async refreshSections() {
      const categoryEndpointsToRefresh = ['leftovers', 'suggested']
      await Promise.all(categoryEndpointsToRefresh.map(async (categoryEndpoint) => {
        const section = this.sections.find(section => section.endpoint === categoryEndpoint)
        if (section) {
          section['loaded'] = false
          this.getRecipes(section.endpoint).then(response => {
            if (response && response.hasOwnProperty('results')) {
              section['recipes'] = response.results
              section['page'] = response
              section['loaded'] = true
            }
          })
        }
      }))
      if (this.$store.state.profile && this.$store.state.profile.show_recipe_reload_explanation_popup) {
          this.$store.dispatch('openModal', { name: 'recipereloadexplanation' })
      }
      this.recordRecipeImpressions(categoryEndpointsToRefresh)
    },
    async addSectionsToView (sections) {
      // Remove sections without recipes
      const noRecipesSections = sections.filter(s => s.recipes === undefined)
      noRecipesSections.forEach(noRecipeSection => {
        this.sections = this.sections.filter(
          existingSection => existingSection.endpoint !== noRecipeSection.endpoint
        )
      })

      // Update remaining categories
      const recipeContainingSections = sections.filter(s => s.recipes !== undefined)
      recipeContainingSections.forEach(recipeContainingSection => {
        if (recipeContainingSection && this.sections) {
          let existingSection = this.sections.find(s => s.endpoint === recipeContainingSection.endpoint)
          if (existingSection) {
            existingSection['recipes'] = recipeContainingSection.recipes
            existingSection['page'] = recipeContainingSection.page
            existingSection['loaded'] = true
          }
        }
      })
    },
    getRecipesAvailability () {
      const recipeIds = this.sections.filter(section => !!section.recipes).map(section => section.recipes).flat().map(recipe => recipe.id)
      this.$store.dispatch('getRecipesAvailability', recipeIds)
    },
    updatePriceDeltasOfAllRecipes () {
      if (this.$store.state.neworder && this.sections) {
        const recipeIds = this.sections.filter(section => !!section.recipes).map(section => section.recipes).flat().map(recipe => recipe.id)
        const details = {
          url: this.$store.state.api.shop.order + this.$store.state.neworder.id + '/update-recipe-price-deltas/',
          method: 'PATCH',
          data: {
            recipe_ids: recipeIds,
          }
        }
        this.$store.state.loadingPriceDeltas = true
        this.$store.dispatch('apiSend', details).then(result => {
          this.$store.dispatch('save', { key: 'priceDeltas', value: result })
          for (const section of this.sections) {
            if (!section.recipes) continue;

            for (let sectionRecipe of section.recipes) {
              const foundResult = result.find(resultRecipe => resultRecipe.recipe_id === sectionRecipe.id)
              if (foundResult) sectionRecipe.price_delta = foundResult.price_delta
            }
          }
          this.$store.state.loadingPriceDeltas = false
        })
      }
    },
    recordRecipeImpressions(endpointsToRecord=undefined) {
      if (endpointsToRecord === undefined) {
        // default to all endpoints in this.sections
        endpointsToRecord = this.sections.map(section => section.endpoint)
      }
      
      let impressions = []
      for (const sectionEndpoint of endpointsToRecord) {
        const section = this.sections.find(section => section.endpoint === sectionEndpoint)
        if (section) {
          for (const recipe of section.recipes) {
            const impression = {recipe: recipe.id, flag: this.$store.state.recipeImpressionFlags.SEEN}
            impressions.push(impression)
          }
        }
      }
      this.$store.dispatch('recordRecipeImpression', impressions)
    }
  },
  mounted () {
    this.downloadRecipeData()
  }
}
</script>
